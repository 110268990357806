import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';

gsap.registerPlugin(ScrollTrigger);

const slider = '.js-content-slider';
const contentSlider = sliderElm => {
    const sliderIdentifier = sliderElm.id;
    const type = sliderElm.dataset.type;
    const slideEl = sliderElm.querySelectorAll('.content-slider__item');

    let slides_per_view_desktop = 2.25;
    let slides_per_view_mobile = 1.5;

    if (type == 'reviews') {
        slides_per_view_desktop = 1.15;
        slides_per_view_mobile = 1.15;
    }
    if (type == 'blog') {
        slides_per_view_desktop = 3;
        slides_per_view_mobile = 1.5;
    }

    const swiper = new Swiper(`#${sliderIdentifier}`, {
        modules: [Navigation, Scrollbar],
        slidesPerView: slides_per_view_mobile,
        loop: false,
        spaceBetween: 16,
        navigation: {
            nextEl: '.js-swiper-next',
            prevEl: '.js-swiper-prev',
            lockClass: 'is-hidden',
            disabledClass: 'is-disabled',
        },
        scrollbar: {
            el: '.js-swiper-scrollbar',
            draggable: true,
        },
        breakpoints: {
            1480: {
                slidesPerView: slides_per_view_desktop,
                spaceBetween: 102,
            },
            1280: {
                slidesPerView: slides_per_view_desktop,
                spaceBetween: 80,
            },
            520: {
                slidesPerView: slides_per_view_desktop,
                spaceBetween: 60,
            },
        },
    });

    return {
        swiper,
    };
};

const allSliders = document.querySelectorAll(slider);
allSliders.forEach(slider => contentSlider(slider));
